import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visible)?_c(VCard,{staticStyle:{"margin":"9px 0"},attrs:{"tile":"","flat":""}},[_c(VList,{attrs:{"dense":""}},[_c(VSubheader,{staticStyle:{"justify-content":"center","font-size":"1rem","font-weight":"bold"}},[_vm._v("Top 10 de Categorias")]),_c(VListItemGroup,{attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:i,attrs:{"justify":"space-between","inactive":""},on:{"click":function($event){return _vm.$emit('selectedCategory', item)}}},[_c('span',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(item.name))]),_c(VChip,{staticStyle:{"justify-content":"center","max-width":"50px","width":"50px"}},[_vm._v(_vm._s(item.companies_count))])],1)}),1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }